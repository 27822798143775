<template>
    <div>
        <div :class="$style.subbar" v-if="instance" class="mt-3">
            <div class="dF aC mb-0">
                <div>
                    <div>
                        <a-breadcrumb>
                            <a-breadcrumb-item>{{
                                instance.name
                            }}</a-breadcrumb-item>
                            <a-breadcrumb-item>{{
                                selectedAppInitial
                            }}</a-breadcrumb-item>
                            <a-breadcrumb-item v-if="crumbs != ''">{{
                                crumbs
                            }}</a-breadcrumb-item>
                        </a-breadcrumb>
                    </div>
                </div>
                <div :class="$style.amount" class="ml-auto d-none d-sm-flex">
                    <button
                        @click="$store.commit('SHOW_ADD_FLOOR')"
                        type="button"
                        style="height: 40px; width: 180px"
                        class="
                            btn btn-primary
                            mr-auto
                            text-nowrap
                            d-none d-md-block
                        "
                    >
                        <i class="fe fe-edit btn-addon-icon" /> &nbsp; FLOOR
                        SETTINGS
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
        selectedAppInitial() {
            return (
                (this.$store.state.theApp &&
                    this.$store.state.theApp.media &&
                    this.$store.state.theApp.media.initial) ||
                "CGC"
            );
        },
        instance() {
            return this.$store.state.instance;
        },
        crumbs() {
            return this.$store.state.condogrid.crumbs;
        },
    },
};
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
