<template>
  <div id="app">
    
    <bh-loading :show="appLoading" loadingText="Loading Floor" />
    <a-modal
      :title="modal.title"
      :visible="modal.show"
      @ok="$store.dispatch('CONFIRM_MODAL')"
      :confirmLoading="modal.load"
      @cancel="$store.commit('CLOSE_MODAL')"
      >
      <p>{{modal.text}}</p>
    </a-modal>
    <MediaSelector />
    <localization></localization>
  </div>
</template>

<script>
import bhLoading from 'bh-mod/components/common/Loading'
import { mapGetters } from 'vuex'
import Localization from 'bh-mod/localization'
import MediaSelector from 'bh-mod/components/file/MediaSelector'

export default {
  name: 'app',
  components: { Localization,MediaSelector,bhLoading },
  computed: {
    ...mapGetters(['user']),
    modal(){
      return this.$store.state.condogrid.modal
    },
    appLoading(){
      return this.$store.state.condogrid.appLoading
    }
  },
  watch: {
    '$route'(to, from) {
      const query = Object.assign({}, to.query)
      this.$store.commit('SETUP_URL_SETTINGS', query)
    },
  },
  methods:{

  }
}
</script>
<style>
  #app{
    min-height: 100vh;
  }
  .wcp-editor-toolbar-button.wcp-active{
    background:var(--primary);
  }
  .wcp-editor-toolbar-button-icon{
    display:flex;
    align-items: center;
  }
  .wcp-editor-tooltip-right .wcp-editor-tooltip-visible{
    background:#3F3356;
  }
</style>